








































import { Component, Vue, Prop } from "vue-property-decorator";
import Jianyan from "../jianyan/list.vue";
import Jiancha from "../jiancha/list.vue";
import Chufang from "../chufang/list.vue";
@Component({
  components: {
    Jianyan,
    Jiancha,
    Chufang,
  },
})
export default class Name extends Vue {
  private type: any = "详情";
  private changeTab(val: any) {
    this.type = val;
  }
}
